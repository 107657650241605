import React, { FC, useState } from "react";
import { Button, Card, Col, Pagination, Row, Typography } from "antd";
import { useTopContributors } from "./hooks";
import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { useNavigateToProfile } from "@dewo/app/util/navigation";
import styles from "./TopContributors.module.less";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { QuestionmarkTooltip } from "@dewo/app/components/QuestionmarkTooltip";
import Link from "next/link";
import { deworkSocialLinks } from "@dewo/app/util/constants";

export const TopContributors: FC = () => {
  const [page, setPage] = useState(1);
  const perPage = 9;
  const topContributors = useTopContributors({ limit: 1000, offset: 0 });
  const navigateToProfile = useNavigateToProfile();
  const myUser = useAuthContext().user;

  return (
    <>
      <Typography.Title level={3}>Contributors</Typography.Title>
      {!!myUser && !myUser.revenueShare && (
        <Card
          className="dewo-card-highlighted"
          bodyStyle={{ padding: 12 }}
          style={{ marginBottom: 16 }}
        >
          <Row justify="space-between" className="w-full" align="middle">
            <Col>
              <Typography.Paragraph style={{ margin: 0 }}>
                Enable revenue sharing to show up on the contributors page.{" "}
                <Link href={deworkSocialLinks.gitbook.revenueSharing}>
                  <a
                    target="_blank"
                    // @ts-ignore
                    name="Revenue sharing (Learn more)"
                  >
                    Learn more...
                  </a>
                </Link>
              </Typography.Paragraph>
            </Col>

            <Col>
              <Button type="primary" onClick={() => navigateToProfile(myUser)}>
                Enable revenue sharing
              </Button>
            </Col>
          </Row>
        </Card>
      )}

      <Row gutter={[12, 12]} align="stretch">
        {topContributors?.users
          ?.slice((page - 1) * perPage, page * perPage)
          .map((c) => (
            <Col lg={8} md={12} sm={24} key={c.id}>
              <Card
                bodyStyle={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 16,
                  height: 150,
                }}
                style={{
                  height: "100%",
                }}
                className="bg-body-secondary hover:component-highlight cursor-pointer h-full"
                bordered={false}
                hoverable
                onClick={() => navigateToProfile(c)}
              >
                <Card.Meta
                  className={styles.cardMeta}
                  avatar={<UserAvatar size="large" linkToProfile user={c} />}
                  title={c.username}
                  description={
                    <Typography.Text type="secondary">
                      <QuestionmarkTooltip
                        marginLeft={8}
                        title={
                          <>
                            The reputation score is equal to the total amount of
                            reputation credits staked in you. To get a higher
                            score, get more stakers!{" "}
                            <Link
                              href={deworkSocialLinks.gitbook.revenueSharing}
                            >
                              <a
                                target="_blank"
                                // @ts-ignore
                                name="Revenue sharing (Learn more)"
                              >
                                Learn more...
                              </a>
                            </Link>
                          </>
                        }
                      >
                        Reputation:{" "}
                        <Typography.Text>{c.reputationScore}</Typography.Text>
                      </QuestionmarkTooltip>
                    </Typography.Text>
                  }
                />
                <Typography.Paragraph
                  ellipsis={{ rows: 2 }}
                  style={{ marginTop: 16 }}
                >
                  {c.bio}
                </Typography.Paragraph>
              </Card>
            </Col>
          ))}
      </Row>

      <Row justify="center" style={{ marginTop: 16 }}>
        <Pagination
          current={page}
          pageSize={perPage}
          onChange={(a) => setPage(a)}
          total={topContributors?.users?.length}
        />
      </Row>
    </>
  );
};
