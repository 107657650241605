import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Spin, Typography, Input, Skeleton } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./OrganizationDiscoveryList.module.less";
import { usePopularOrganizations } from "../../organization/hooks";
import { useGraphNode } from "../../graph/hooks";
import { Constants } from "@dewo/app/util/constants";
import { Organization } from "@dewo/app/graphql/types";
import { OrganizationTable } from "./OrganizationTable";

export const OrganizationDiscoveryList: FC = () => {
  const organizations = usePopularOrganizations();

  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const featuredNode = useGraphNode(
    Constants.LANDING_PAGE_FEATURED_ORGANIZATIONS_NODE_ID
  );
  const featuredOrganizations = useMemo(
    () =>
      featuredNode?.children
        .map((e) => e.node.organization)
        .filter((o): o is Organization => !!o),
    [featuredNode?.children]
  );

  const [searchText, setSearchText] = useState("");
  const onChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value),
    []
  );
  const filteredOrganizations = useMemo(
    () =>
      organizations
        ?.filter((o) =>
          [o.name, o.description].some(
            (v) => !!v?.toLowerCase().includes(searchText.toLowerCase())
          )
        )
        .filter(
          (o) => featuredOrganizations?.some((f) => f.id === o.id) !== true
        ),
    [searchText, organizations, featuredOrganizations]
  );
  if (!mounted) return null;
  return (
    <>
      <Typography.Title level={1} style={{ textAlign: "center", fontSize: 24 }}>
        <span role="img">🌍</span> Top DAOs{" "}
        {!!filteredOrganizations && `(${filteredOrganizations.length})`}
      </Typography.Title>
      <Typography.Paragraph
        type="secondary"
        style={{ textAlign: "center", marginBottom: 24 }}
      >
        Find hundreds of web3 DAOs, see their roadmap and explore open bounties
        and work
      </Typography.Paragraph>

      {!!filteredOrganizations ? (
        <div className="mx-auto max-w-md w-full">
          <Input
            placeholder="Search DAOs..."
            allowClear
            onChange={onChangeSearch}
            prefix={<SearchOutlined />}
            size="large"
            className={styles.searchbar}
          />
          {!!featuredOrganizations?.length && (
            <>
              <Typography.Title
                style={{ textTransform: "uppercase" }}
                level={5}
              >
                Featured
              </Typography.Title>
              <OrganizationTable organizations={featuredOrganizations} />
              <Typography.Title
                style={{ textTransform: "uppercase", marginTop: 24 }}
                level={5}
              >
                Popular
              </Typography.Title>
            </>
          )}

          <Skeleton active loading={!filteredOrganizations}>
            <OrganizationTable organizations={filteredOrganizations ?? []} />
          </Skeleton>
        </div>
      ) : (
        <div style={{ display: "grid" }}>
          <Spin />
        </div>
      )}
    </>
  );
};
