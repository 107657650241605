import React, { FC, useCallback } from "react";
import { PageHeader, Tabs, Tag, Typography } from "antd";
import styles from "./LandingPage.module.less";
import { useRouter } from "next/router";
import { OrganizationDiscoveryList } from "./organizations/OrganizationDiscoveryList";
import {
  TaskDiscoveryList,
  TaskDiscoveryListSection,
} from "./bounties/TaskDiscoveryList";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { TopContributors } from "../user/TopContributors";

interface Props {
  currentTab?: string;
}

const bountySections: TaskDiscoveryListSection[] = [
  {
    label: "🔥 Featured",
    tooltip: (
      <Typography.Text style={{ whiteSpace: "pre-line" }}>
        High-quality bounties are marked as 'featured', which means that they:
        {"\n"}
        <ol>
          <li>
            Have a bounty in a token listed on Coinmarketcap, so that the
            dollar-value can be known
          </li>
          <li>Have a clear description</li>
          <li>
            The workspace is connected to Discord, so you easily can talk with
            the applicant
          </li>
        </ol>
      </Typography.Text>
    ),
    query: { featured: true, reward: { exists: true } },
  },
  {
    label: "All Bounties",
    query: { featured: false, reward: { exists: true } },
  },
];

export const LandingPage: FC<Props> = ({ currentTab = "organizations" }) => {
  const router = useRouter();
  const navigateToTab = useCallback(
    (tab: string) => router.push(tab === "organizations" ? "/" : `/${tab}`),
    [router]
  );

  return (
    <>
      <PageHeader />
      <Tabs
        centered
        className={styles.tabs}
        onTabClick={navigateToTab}
        activeKey={currentTab}
      >
        <Tabs.TabPane tab="🌍 Top DAOs" key="organizations">
          <OrganizationDiscoveryList />
        </Tabs.TabPane>
        <Tabs.TabPane tab="🔥 Bounties" key="bounties">
          <TaskDiscoveryList sections={bountySections} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <>
              <UsergroupAddOutlined /> Contributors
              <Tag color="green" style={{ marginLeft: 8 }}>
                New
              </Tag>
            </>
          }
          key="contributors"
          className="mx-auto w-full max-w-lg"
        >
          <TopContributors />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
