import {
  Language,
  SearchTasksInput,
  TaskStatus,
  TaskViewSortByDirection,
  TaskViewSortByField,
} from "@dewo/app/graphql/types";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import React, { FC, Fragment, ReactNode, useMemo } from "react";
import { TaskDiscoveryTable } from "./TaskDiscoveryTable";
import { FormSection } from "@dewo/app/components/form/FormSection";
import { SkillCloudInput } from "./SkillCloudInput";
import { useToggle } from "@dewo/app/util/hooks";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { QuestionmarkTooltip } from "@dewo/app/components/QuestionmarkTooltip";
import { useTaskViewLayoutData } from "../../task/views/hooks";
import { LanguageInput } from "./LanguageInput";
import { DebouncedInput } from "@dewo/app/components/form/DebouncedInput";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

interface FilterValues {
  skillIds: string[] | undefined;
  name: string | undefined;
  languages: Language[] | undefined;
  sortBy: TaskViewSortByField.createdAt | TaskViewSortByField.reward;
}

const defaultFilterValues: FilterValues = {
  sortBy: TaskViewSortByField.createdAt,
  name: undefined,
  skillIds: [],
  languages: [],
};

export interface TaskDiscoveryListSection {
  label?: string;
  tooltip?: ReactNode;
  query: Partial<SearchTasksInput>;
}

interface Props {
  sections: TaskDiscoveryListSection[];
}

export const TaskDiscoveryList: FC<Props> = ({ sections }) => {
  const [form] = useForm<FilterValues>();
  const skillIds = useWatch("skillIds", form);
  const name = useWatch("name", form);
  const languages = useWatch("languages", form);
  const sortBy = useWatch("sortBy", form) ?? defaultFilterValues.sortBy;

  const data = useTaskViewLayoutData(
    useMemo(
      (): SearchTasksInput[] =>
        sections.map((section) => ({
          statuses: [TaskStatus.TODO],
          sortBy: {
            field: sortBy,
            direction: TaskViewSortByDirection.DESC,
          },
          name: !!name ? name : undefined,
          skillIds: !!skillIds?.length ? skillIds : undefined,
          languages: !!languages?.length ? languages : undefined,
          assigneeIds: [null],
          parentTaskId: null,
          ...section.query,
        })),
      [skillIds, languages, sortBy, name, sections]
    ),
    { withOrganization: true }
  );

  const screens = useBreakpoint();
  const filters = useToggle(true);
  return (
    <>
      <div className="mx-auto max-w-lg w-full">
        <Row gutter={16} justify="center">
          <Col md={8}></Col>
          <Col md={16}>
            <Typography.Title level={1} style={{ fontSize: 24 }}>
              Open Bounties
            </Typography.Title>
            <Typography.Paragraph type="secondary" style={{ marginBottom: 24 }}>
              Find and explore tasks and bounties across hundreds of DAOs
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} md={8}>
            <Card
              style={{ marginTop: 0 }}
              className="bg-body-secondary"
              size="small"
              title="Filters"
              extra={
                !screens.sm && (
                  <Button
                    type="text"
                    icon={
                      filters.isOn ? (
                        <EyeInvisibleOutlined onClick={filters.toggleOff} />
                      ) : (
                        <EyeOutlined onClick={filters.toggleOn} />
                      )
                    }
                  />
                )
              }
            >
              <Form
                form={form}
                initialValues={defaultFilterValues}
                layout="vertical"
                style={filters.isOn ? {} : { display: "none" }}
              >
                <Form.Item name="name" style={{ marginTop: 8 }}>
                  <DebouncedInput placeholder="Search by name..." />
                </Form.Item>
                <Form.Item label="Sorting" name="sortBy">
                  <Select>
                    <Select.Option value="createdAt">
                      Creation date (newest first)
                    </Select.Option>
                    <Select.Option value="reward">
                      Bounty size (highest)
                    </Select.Option>
                  </Select>
                </Form.Item>
                <FormSection label="Skills">
                  <Typography.Paragraph
                    type="secondary"
                    className="ant-typography-caption"
                  >
                    Click on skills to show tasks for
                  </Typography.Paragraph>
                  <Form.Item name="skillIds">
                    <SkillCloudInput />
                  </Form.Item>
                </FormSection>
                <Form.Item label="Languages" name="languages">
                  <LanguageInput />
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col sm={24} md={16}>
            {sections.map(
              (section, index) =>
                data[index].tasks?.length !== 0 && (
                  <Fragment key={index}>
                    {!!section.label && (
                      <Typography.Title
                        style={{ textTransform: "uppercase" }}
                        level={5}
                      >
                        {!!section.tooltip ? (
                          <QuestionmarkTooltip
                            marginLeft={8}
                            title={section.tooltip}
                          >
                            {section.label}
                          </QuestionmarkTooltip>
                        ) : (
                          section.label
                        )}
                      </Typography.Title>
                    )}
                    {!!data[index].tasks ? (
                      <TaskDiscoveryTable
                        key={[
                          index,
                          JSON.stringify({ name, skillIds, languages, sortBy }),
                        ].join("/")}
                        data={data[index]}
                      />
                    ) : (
                      <Skeleton />
                    )}
                  </Fragment>
                )
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
