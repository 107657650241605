import React, { FC } from "react";
import { Table, Typography, Tag, Row, Col } from "antd";
import { useRouter } from "next/router";
import Link from "next/link";
import { OrganizationAvatar } from "@dewo/app/components/avatars/OrganizationAvatar";
import { UsergroupAddOutlined } from "@ant-design/icons";
import styles from "./OrganizationDiscoveryList.module.less";
import { Organization } from "@dewo/app/graphql/types";

export const OrganizationTable: FC<{
  organizations: (Organization & { userCount?: number })[];
}> = ({ organizations }) => {
  const router = useRouter();
  return (
    <Table
      dataSource={organizations}
      pagination={{
        hideOnSinglePage: true,
        onChange: (page) => router.push({ query: { ...router.query, page } }),
        current: Number(router.query.page) || 1,
      }}
      size="small"
      tableLayout="fixed"
      rowClassName="hover:cursor-pointer"
      className="dewo-discovery-table"
      rowKey="id"
      onRow={(o) => ({ onClick: () => router.push(o.permalink) })}
      showHeader={false}
      columns={[
        {
          key: "organization",
          render: (_: unknown, organization) => (
            <Link href={organization.permalink}>
              <a>
                <Row align="middle" style={{ columnGap: 16 }}>
                  <OrganizationAvatar
                    organization={organization}
                    size={64}
                    tooltip={{ title: "View DAO profile" }}
                  />
                  <Row align="middle" style={{ flex: 1 }}>
                    <Col className={styles.rowTextContainer}>
                      <Typography.Title
                        level={3}
                        ellipsis={{ rows: 1 }}
                        style={{ margin: 0, fontSize: 16 }}
                      >
                        {organization.name}
                      </Typography.Title>
                      {!!organization.tagline && (
                        <Typography.Paragraph
                          type="secondary"
                          ellipsis={{ rows: 3 }}
                          style={{ margin: 0 }}
                        >
                          {organization.tagline}
                        </Typography.Paragraph>
                      )}
                    </Col>
                    {!!organization.userCount && (
                      <Col>
                        <Tag
                          icon={<UsergroupAddOutlined />}
                          style={{ marginTop: 8, marginBottom: 8 }}
                        >
                          {organization.userCount} members
                        </Tag>
                      </Col>
                    )}
                  </Row>
                </Row>
              </a>
            </Link>
          ),
        },
      ]}
    />
  );
};
